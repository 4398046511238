import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const messages = [
  "Maybe the page is on a coffee break?",
  "Looks like this URL took a wrong turn.",
  "Lost in the void of the internet.",
  "Well, this is awkward. Page not found.",
  "Oops, our map seems outdated.",
  "404. Page not found, but you're still awesome.",
];

const NotFound = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  // Select a random message for display
  const randomMessage = messages[Math.floor(Math.random() * messages.length)];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        textAlign: "center",
        padding: 3,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontWeight: "bold",
          color: theme.palette.primary.main,
          mb: 2,
          fontSize: { xs: "3rem", sm: "5rem" },
        }}
      >
        404
      </Typography>
      <Typography
        variant="h5"
        sx={{
          mb: 3,
          color: theme.palette.text.secondary,
          fontStyle: "italic",
        }}
      >
        {randomMessage}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mb: 4,
          maxWidth: "600px",
          color: theme.palette.text.secondary,
        }}
      >
        The page you're looking for might have been moved, deleted, or you might
        have mistyped the URL. Don't worry, it happens to the best of us.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => navigate("/dashboard")}
        sx={{
          textTransform: "none",
          fontWeight: "bold",
          px: 4,
          py: 1.5,
        }}
      >
        Back to Safety
      </Button>
    </Box>
  );
};

export default NotFound;
