import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Grid,
  Alert,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import axios from "axios";

const genders = ["Male", "Female", "Other"];
const relationships = ["Parent", "Sibling", "Spouse", "Friend", "Guardian"];

const PatientRegister = () => {
  const [patient, setPatient] = useState({
    name: "",
    dob: "",
    gender: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    town: "",
    county: "",
    postcode: "",
    contact_number: "",
    next_of_kin_name: "",
    next_of_kin_contact: "",
    relationship_to_nok: "",
    past_medical_history: "",
    medications: "",
    allergies: "",
    email: "",
    password: "",
    cheer_team_id: "",
    cheer_squad_id: "",
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [privacyConsent, setPrivacyConsent] = useState(false);
  const [createAccount, setCreateAccount] = useState(false);
  const [isBCACheerMember, setIsBCACheerMember] = useState(false);
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [cheerTeams, setCheerTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedSquad, setSelectedSquad] = useState(null);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [searchAddress, setSearchAddress] = useState("");

  useEffect(() => {
    const fetchCheerTeams = async () => {
      try {
        const response = await axios.get("/api/cheer/teams");
        setCheerTeams(response.data);
      } catch (error) {
        console.error("Error fetching cheer teams:", error);
      }
    };
    fetchCheerTeams();
  }, []);

  const [isSearching, setIsSearching] = useState(false);

  const handleAddressSearch = async () => {
    if (searchAddress.length > 3) {
      setIsSearching(true);
      try {
        const response = await axios.get("https://api.opencagedata.com/geocode/v1/json", {
          params: {
            q: searchAddress,
            key: "29b83171db2646e6b9e90a3a3c62cc0a",
            countrycode: "GB",
            limit: 5,
          },
        });
  
        const suggestions = response.data.results.map((result) => ({
          formatted: result.formatted,
          components: result.components,
        }));
  
        setAddressSuggestions(suggestions);
      } catch (err) {
        console.error("Address lookup failed", err);
        setAddressSuggestions([]);
      } finally {
        setIsSearching(false);
      }
    }
  };
  
  const handleAddressSelect = (selected) => {
    const components = selected.components;
  
    setPatient((prev) => ({
      ...prev,
      addressLine1: components.road || "",
      addressLine2: components.residential || "",
      addressLine3: components.suburb || "",
      town: components.town || components.city || "",
      county: components.county || "",
      postcode: components.postcode || "",
    }));
  
    setAddressSuggestions([]);
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPatient((prev) => ({ ...prev, [name]: value }));
  };

  const handleConsentChange = (e) => setPrivacyConsent(e.target.checked);

  const handleBCAChange = (e) => {
    setIsBCACheerMember(e.target.checked);
    if (!e.target.checked) {
      setSelectedTeam(null);
      setSelectedSquad(null);
      setPatient((prev) => ({
        ...prev,
        cheer_team_id: "",
        cheer_squad_id: "",
      }));
    }
  };

  const handleAccountToggle = (e) => setCreateAccount(e.target.checked);

  const handleTeamChange = (event, newValue) => {
    setSelectedTeam(newValue);
    setSelectedSquad(null);
    setPatient((prev) => ({
      ...prev,
      cheer_team_id: newValue ? newValue.team_id : "",
      cheer_squad_id: "",
    }));
  };

  const handleSquadChange = (event, newValue) => {
    setSelectedSquad(newValue);
    setPatient((prev) => ({
      ...prev,
      cheer_squad_id: newValue ? newValue.squad_id : "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!privacyConsent) {
      setError("You must accept the privacy policy and terms to register.");
      return;
    }

    const fullAddress = [
      patient.addressLine1,
      patient.addressLine2,
      patient.addressLine3,
      patient.town,
      patient.county,
      patient.postcode,
    ]
      .filter((part) => part) // Remove empty fields
      .join(", ");

    setError("");
    setSuccessMessage("");
    setLoading(true);

    try {
      let user_id = null;
      if (createAccount) {
        const userResponse = await axios.post("/api/users/register", {
          email: patient.email,
          password: patient.password,
          role: "patient",
        });
        user_id = userResponse.data.user_id;
      }

      await axios.post("/api/patients/register", {
        ...patient,
        address: fullAddress, // Combine the full address string
        user_id,
      });

      setSuccessMessage("Registration successful!");
      setPatient({
        name: "",
        dob: "",
        gender: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        town: "",
        county: "",
        postcode: "",
        contact_number: "",
        next_of_kin_name: "",
        next_of_kin_contact: "",
        relationship_to_nok: "",
        past_medical_history: "",
        medications: "",
        allergies: "",
        email: "",
        password: "",
        cheer_team_id: "",
        cheer_squad_id: "",
      });
      setPrivacyConsent(false);
      setCreateAccount(false);
    } catch (err) {
      console.error(err);
      setError("Registration failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="md" sx={{ mt: 4 }}>
      <Paper elevation={8} sx={{ padding: 4, borderRadius: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Welcome to Patient Registration
          </Typography>
          <Typography variant="subtitle1" gutterBottom sx={{ mb: 3 }}>
            This form is used to collect essential personal and medical
            information for managing patient care during events. Your data will
            be handled in compliance with GDPR and other applicable standards.
          </Typography>
          <Box sx={{ mb: 4 }}>
            <Typography variant="body2" color="text.secondary">
              Have questions?{" "}
              <Button
                variant="text"
                size="small"
                color="primary"
                startIcon={<HelpOutlineIcon />}
              >
                Contact Support
              </Button>
            </Typography>
          </Box>
          {error && <Alert severity="error">{error}</Alert>}
          {successMessage && <Alert severity="success">{successMessage}</Alert>}

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 2, width: "100%" }}
          >
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Personal Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="name"
                      label="Full Name"
                      name="name"
                      value={patient.name}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="dob"
                      label="Date of Birth"
                      name="dob"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      value={patient.dob}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      options={genders}
                      value={patient.gender}
                      onChange={(event, newValue) =>
                        setPatient((prev) => ({ ...prev, gender: newValue }))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Gender"
                          required
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h6" gutterBottom>
                      Address
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="searchAddress"
                      label="Search Address"
                      value={searchAddress}
                      onChange={(e) => setSearchAddress(e.target.value)}
                      fullWidth
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSearching || searchAddress.length < 3}
                      onClick={handleAddressSearch}
                      sx={{ mt: 2 }}
                    >
                      {isSearching ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "Search"
                      )}
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      freeSolo
                      options={addressSuggestions.map((s) => s.formatted)}
                      renderInput={(params) => (
                        <TextField {...params} label="Address Suggestions" />
                      )}
                      onChange={(e, value) => {
                        const selected = addressSuggestions.find(
                          (s) => s.formatted === value
                        );
                        if (selected) handleAddressSelect(selected);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="addressLine1"
                      label="Address Line 1"
                      value={patient.addressLine1}
                      onChange={(e) =>
                        setPatient((prev) => ({
                          ...prev,
                          addressLine1: e.target.value,
                        }))
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="addressLine2"
                      label="Address Line 2"
                      value={patient.addressLine2}
                      onChange={(e) =>
                        setPatient((prev) => ({
                          ...prev,
                          addressLine2: e.target.value,
                        }))
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="addressLine3"
                      label="Address Line 3"
                      value={patient.addressLine3}
                      onChange={(e) =>
                        setPatient((prev) => ({
                          ...prev,
                          addressLine3: e.target.value,
                        }))
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="town"
                      label="Town"
                      value={patient.town}
                      onChange={(e) =>
                        setPatient((prev) => ({
                          ...prev,
                          town: e.target.value,
                        }))
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="county"
                      label="County"
                      value={patient.county}
                      onChange={(e) =>
                        setPatient((prev) => ({
                          ...prev,
                          county: e.target.value,
                        }))
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="postcode"
                      label="Postcode"
                      value={patient.postcode}
                      onChange={(e) =>
                        setPatient((prev) => ({
                          ...prev,
                          postcode: e.target.value,
                        }))
                      }
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Next of Kin Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="next_of_kin_name"
                      label="Next of Kin Name"
                      name="next_of_kin_name"
                      value={patient.next_of_kin_name}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="next_of_kin_contact"
                      label="Next of Kin Contact"
                      name="next_of_kin_contact"
                      value={patient.next_of_kin_contact}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      options={relationships}
                      value={patient.relationship_to_nok}
                      onChange={(event, newValue) =>
                        setPatient((prev) => ({
                          ...prev,
                          relationship_to_nok: newValue,
                        }))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Relationship to Next of Kin"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Medical Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="past_medical_history"
                      label="Past Medical History"
                      name="past_medical_history"
                      value={patient.past_medical_history}
                      onChange={handleChange}
                      fullWidth
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="medications"
                      label="Medications"
                      name="medications"
                      value={patient.medications}
                      onChange={handleChange}
                      fullWidth
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="allergies"
                      label="Allergies"
                      name="allergies"
                      value={patient.allergies}
                      onChange={handleChange}
                      fullWidth
                      multiline
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">BCA Cheer Membership</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isBCACheerMember}
                      onChange={handleBCAChange}
                      color="primary"
                    />
                  }
                  label="I am part of the BCA Cheer Comps"
                />
                {isBCACheerMember && (
                  <>
                    <Autocomplete
                      options={cheerTeams}
                      getOptionLabel={(option) => option.team_name}
                      value={selectedTeam}
                      onChange={(event, newValue) => {
                        setSelectedTeam(newValue);
                        setSelectedSquad(null); // Reset squad selection when team changes
                        setPatient((prev) => ({
                          ...prev,
                          cheer_team_id: newValue ? newValue.team_id : "",
                          cheer_squad_id: "",
                        }));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Cheer Team"
                          required
                          fullWidth
                          sx={{ mt: 2 }}
                        />
                      )}
                    />

                    {selectedTeam?.squads?.length > 0 ? (
                      <Autocomplete
                        options={selectedTeam.squads}
                        getOptionLabel={(option) => option.squad_name}
                        value={selectedSquad}
                        onChange={handleSquadChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Cheer Squad"
                            required
                            fullWidth
                          />
                        )}
                      />
                    ) : (
                      <Typography sx={{ mt: 2 }}>
                        No squads available for this team.
                      </Typography>
                    )}
                  </>
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Account Creation</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={createAccount}
                      onChange={handleAccountToggle}
                      color="primary"
                    />
                  }
                  label="Create an account to access and update your information"
                />
                {createAccount && (
                  <>
                    <TextField
                      required
                      id="email"
                      label="Email"
                      name="email"
                      type="email"
                      value={patient.email}
                      onChange={handleChange}
                      fullWidth
                      sx={{ mt: 2 }}
                    />
                    <TextField
                      required
                      id="password"
                      label="Password"
                      name="password"
                      type="password"
                      value={patient.password}
                      onChange={handleChange}
                      fullWidth
                      sx={{ mt: 2 }}
                    />
                  </>
                )}
              </AccordionDetails>
            </Accordion>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={privacyConsent}
                      onChange={handleConsentChange}
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body2">
                      I agree to the{" "}
                      <Button
                        variant="text"
                        color="primary"
                        onClick={() => setShowPrivacyPolicy(true)}
                        style={{
                          textTransform: "none",
                          padding: 0,
                          minWidth: 0,
                        }}
                      >
                        Privacy Policy
                      </Button>{" "}
                      and{" "}
                      <Button
                        variant="text"
                        color="primary"
                        onClick={() => setShowTerms(true)}
                        style={{
                          textTransform: "none",
                          padding: 0,
                          minWidth: 0,
                        }}
                      >
                        Terms of Service
                      </Button>
                      .
                    </Typography>
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={loading || !privacyConsent}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Register"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
      <Dialog
        open={showPrivacyPolicy}
        onClose={() => setShowPrivacyPolicy(false)}
        maxWidth="md"
      >
        <DialogTitle>Privacy Policy</DialogTitle>
        <DialogContent dividers>
          <Typography variant="subtitle1" gutterBottom>
            Effective Date: 28/10/2024
          </Typography>
          <Typography variant="body1" gutterBottom>
            DC Event Medical Ltd ("we", "our", or "us") is committed to ensuring
            the privacy and protection of your personal data in line with the UK
            General Data Protection Regulation (UK GDPR), the Data Protection
            Act 2018, and other relevant laws. This Privacy Policy explains how
            we collect, use, process, and protect your information when you use
            our services, interact with our website, or communicate with us.
          </Typography>

          <Typography variant="h6" gutterBottom>
            1. Who We Are
          </Typography>
          <Typography variant="body2" gutterBottom>
            DC Event Medical Ltd provides event medical services across the UK.
            We specialise in pre-hospital care and ensure compliance with ISO
            9001 for quality management and ISO 27001 for information security.
            We are registered with the Information Commissioner’s Office (ICO),
            reflecting our commitment to safeguarding your data.
            <br />
            <br />
            Data Controller: DC Event Medical Ltd
            <br />
            Data Protection Officer: Liam Cole
            <br />
            Contact: dpo@dceventmedical.co.uk
            <br />
            Address: 4 Falcon Close, Fareham, Hampshire, PO16 8PL
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. Information We Collect
          </Typography>
          <Typography variant="body2" gutterBottom>
            We collect the following types of information:
            <ul>
              <li>
                <strong>Identity Data:</strong> Full name, date of birth,
                gender.
              </li>
              <li>
                <strong>Contact Data:</strong> Address, phone number, email
                address.
              </li>
              <li>
                <strong>Health Data:</strong> Medical history, allergies,
                current medications.
              </li>
              <li>
                <strong>Technical Data:</strong> IP address, browser type,
                device information, and browsing history when accessing our
                website.
              </li>
              <li>
                <strong>Consent Data:</strong> Records of your explicit consent
                to process your data.
              </li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom>
            3. How We Collect Your Data
          </Typography>
          <Typography variant="body2" gutterBottom>
            We collect your data directly from you through:
            <ul>
              <li>Our patient registration forms (online or paper-based).</li>
              <li>Interactions during the provision of medical services.</li>
              <li>Website usage through cookies and tracking technologies.</li>
              <li>Email or telephone correspondence.</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom>
            4. How We Use Your Data
          </Typography>
          <Typography variant="body2" gutterBottom>
            We use your personal data for the following purposes:
            <ul>
              <li>
                <strong>Provision of Services:</strong> To provide safe,
                effective medical care during events.
              </li>
              <li>
                <strong>Records Management:</strong> To maintain accurate
                patient records as required under medical guidelines.
              </li>
              <li>
                <strong>Communication:</strong> To contact you regarding your
                care or event details.
              </li>
              <li>
                <strong>Compliance:</strong> To fulfil legal obligations and
                align with ISO 9001/27001 and CQC guidelines.
              </li>
              <li>
                <strong>Improvement:</strong> To analyse and improve our
                services.
              </li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom>
            5. Sharing Your Data
          </Typography>
          <Typography variant="body2" gutterBottom>
            Your data may be shared with:
            <ul>
              <li>Healthcare professionals directly involved in your care.</li>
              <li>
                Regulatory and legal authorities, such as the ICO, if required.
              </li>
              <li>
                Third-party service providers, such as cloud storage or software
                vendors, under strict data protection agreements.
              </li>
            </ul>
            We do not sell or share your data for marketing purposes.
          </Typography>

          <Typography variant="h6" gutterBottom>
            6. Data Security
          </Typography>
          <Typography variant="body2" gutterBottom>
            We use state-of-the-art security measures, including encryption,
            secure access controls, and regular audits, to ensure your data is
            protected in compliance with ISO 27001 standards. Physical,
            technical, and procedural safeguards are implemented to prevent
            unauthorised access, loss, or misuse of data.
          </Typography>

          <Typography variant="h6" gutterBottom>
            7. Your Rights
          </Typography>
          <Typography variant="body2" gutterBottom>
            You have the following rights under the UK GDPR:
            <ul>
              <li>
                <strong>Access:</strong> Request a copy of your personal data.
              </li>
              <li>
                <strong>Correction:</strong> Request rectification of incorrect
                or incomplete data.
              </li>
              <li>
                <strong>Erasure:</strong> Request deletion of your data where no
                longer required.
              </li>
              <li>
                <strong>Restriction:</strong> Request limitation of data
                processing under certain circumstances.
              </li>
              <li>
                <strong>Objection:</strong> Object to processing based on
                legitimate interests.
              </li>
              <li>
                <strong>Portability:</strong> Receive your data in a portable
                format.
              </li>
            </ul>
            To exercise your rights, please contact us at
            dpo@dceventmedical.co.uk.
          </Typography>

          <Typography variant="h6" gutterBottom>
            8. Retention
          </Typography>
          <Typography variant="body2" gutterBottom>
            We retain your data for as long as necessary to fulfil the purposes
            outlined in this policy or as required by law. Patient records are
            typically retained for a minimum of 8 years in line with medical
            record guidelines.
          </Typography>

          <Typography variant="h6" gutterBottom>
            9. Complaints
          </Typography>
          <Typography variant="body2" gutterBottom>
            If you have concerns about our handling of your data, you may
            contact the ICO:
            <br />
            Website: <a href="https://ico.org.uk">https://ico.org.uk</a>
            <br />
            Telephone: 0303 123 1113
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPrivacyPolicy(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showTerms}
        onClose={() => setShowTerms(false)}
        maxWidth="md"
      >
        <DialogTitle>Terms of Service</DialogTitle>
        <DialogContent dividers>
          <Typography variant="h6" gutterBottom>
            1. Introduction
          </Typography>
          <Typography variant="body2" gutterBottom>
            These Terms of Service ("Terms") govern your use of services
            provided by DC Event Medical Ltd. By accessing or using our
            services, you agree to comply with these Terms.
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. Definitions
          </Typography>
          <Typography variant="body2" gutterBottom>
            <ul>
              <li>
                <strong>"We/Us/Our":</strong> Refers to DC Event Medical Ltd.
              </li>
              <li>
                <strong>"You/Your":</strong> Refers to the individual or entity
                using our services.
              </li>
              <li>
                <strong>"Services":</strong> Refers to medical, administrative,
                and related support services provided by us.
              </li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom>
            3. Your Obligations
          </Typography>
          <Typography variant="body2" gutterBottom>
            You agree to:
            <ul>
              <li>
                Provide accurate, current, and complete information during
                registration or when required.
              </li>
              <li>
                Maintain the confidentiality of account credentials and notify
                us of unauthorised access immediately.
              </li>
              <li>
                Refrain from any unlawful or prohibited activities while using
                our services.
              </li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom>
            4. Limitations of Liability
          </Typography>
          <Typography variant="body2" gutterBottom>
            To the fullest extent permitted by law, DC Event Medical Ltd shall
            not be held liable for:
            <ul>
              <li>Indirect, consequential, or incidental damages.</li>
              <li>
                Delays, interruptions, or technical failures in service
                delivery.
              </li>
              <li>
                Actions resulting from inaccurate or incomplete information
                provided by you.
              </li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom>
            5. Amendments to Terms
          </Typography>
          <Typography variant="body2" gutterBottom>
            We reserve the right to update these Terms at any time. Any
            significant changes will be communicated via email or through our
            website. Continued use of services constitutes acceptance of the
            updated Terms.
          </Typography>

          <Typography variant="h6" gutterBottom>
            6. Governing Law and Jurisdiction
          </Typography>
          <Typography variant="body2" gutterBottom>
            These Terms are governed by the laws of England and Wales. Any
            disputes arising from these Terms shall be subject to the exclusive
            jurisdiction of the courts of England and Wales.
          </Typography>

          <Typography variant="h6" gutterBottom>
            7. Contact
          </Typography>
          <Typography variant="body2" gutterBottom>
            For queries about these Terms, contact us:
            <br />
            Email: admin@dceventmedical.co.uk
            <br />
            Telephone: +44 123 456 7890
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowTerms(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PatientRegister;
