import React from "react";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Box sx={{ mt: 5, px: 3, py: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Privacy Policy
        </Typography>
        <Typography variant="subtitle1" gutterBottom align="center">
          Last Updated: December 2024
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" gutterBottom>
          Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          At DC Event Medical Ltd ("DCEM," "we," "our," or "us"), we are
          committed to protecting and respecting your privacy. This Privacy
          Policy explains how and why we collect, store, use, and share your
          personal data. It also outlines your rights under applicable data
          protection laws, including the UK GDPR and the Data Protection Act
          2018. We prioritise transparency and accountability in all our data
          handling practices to ensure your trust and confidence.
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" gutterBottom>
          Key Terms
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="We, Us, Our"
              secondary="DC Event Medical Ltd, registered at 4 Falcon Close, Fareham, Hampshire, PO16 8PL, company number 11165671."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Personal Data"
              secondary="Any information relating to an identified or identifiable individual. Examples include names, addresses, medical history, and contact details."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Data Subject"
              secondary="The individual to whom the personal data relates. This could be patients, clients, employees, or any other individual interacting with DCEM."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Data Controller"
              secondary="The organisation that determines how and why personal data is processed. DCEM acts as the Data Controller for all personal data we handle."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Data Processor"
              secondary="An organisation that processes data on behalf of the Data Controller. Examples include our IT service providers."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Special Category Data"
              secondary="Sensitive personal data requiring additional protection, such as health information, ethnicity, or biometric data."
            />
          </ListItem>
        </List>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" gutterBottom>
          Contact Information
        </Typography>
        <Typography variant="body1" paragraph>
          For questions, concerns, or to exercise your rights regarding this
          Privacy Policy, you can contact our Data Protection Officer (DPO):
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Name:</strong> Liam Cole
          <br />
          <strong>Email:</strong> liam@dceventmedical.co.uk
          <br />
          <strong>Address:</strong> 4 Falcon Close, Fareham, Hampshire, PO16 8PL
          <br />
          <strong>Telephone:</strong> +44 1234 567890
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" gutterBottom>
          Lawful Basis for Processing Medical Data
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Provision of Care"
              secondary="Necessary for medical purposes, including diagnosis, treatment, and the provision of continuity of care."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Legal Obligations"
              secondary="Compliance with UK laws, such as the Care Quality Commission (CQC) regulations and statutory reporting requirements."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Consent"
              secondary="Explicit consent is obtained where legally required, such as for processing sensitive data outside standard care scenarios."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Vital Interests"
              secondary="Protecting the life or safety of individuals in emergency situations."
            />
          </ListItem>
        </List>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" gutterBottom>
          Personal Data We Collect
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Patients"
              secondary="Identity data (name, date of birth), medical history (conditions, medications, allergies), treatment records, and emergency contact details."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Clients"
              secondary="Contact data (names, email addresses, phone numbers), billing information (invoices, payment records), and service agreements."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Employees"
              secondary="Employment records (contracts, performance reviews), compliance data (qualifications, certifications), and health and safety records."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Website Users"
              secondary="Technical data (IP addresses, browser types), cookies, and website usage analytics for improving user experience."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Third Parties"
              secondary="Data provided by insurers, emergency services, or healthcare providers when necessary for care delivery or compliance."
            />
          </ListItem>
        </List>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" gutterBottom>
          How We Collect Personal Data
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Direct Interactions"
              secondary="Data provided directly by individuals through forms, phone calls, emails, or in-person interactions."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Automated Technologies"
              secondary="Information collected automatically via cookies, analytics tools, and access control systems."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Third Parties"
              secondary="Information shared with us by insurers, regulatory bodies, or other healthcare providers."
            />
          </ListItem>
        </List>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" gutterBottom>
          Detailed Patient Data Handling
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>What We Collect:</strong> Identity data, medical history,
          treatment records, and emergency contact details.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>How It’s Used:</strong> We create and maintain accurate
          medical records, share necessary data with emergency services, conduct
          audits, and ensure compliance with legal obligations.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>How It’s Protected:</strong> Personal data is encrypted using
          AES-256 standards, secured with role-based access controls, and stored
          in ISO 27001-certified data centres. Regular audits and penetration
          tests are conducted to ensure system security.
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" gutterBottom>
          Rights for Patients
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Right to Access"
              secondary="You can request a copy of your medical records at any time."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Right to Restrict Processing"
              secondary="You can request limitations on how your data is processed in certain circumstances."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Right to be Informed"
              secondary="You will be notified if a data breach occurs that affects your personal information."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Right to Rectification"
              secondary="You can request corrections to any inaccurate or incomplete data."
            />
          </ListItem>
        </List>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" gutterBottom>
          Data Sharing
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="NHS"
              secondary="Data shared for continuity of care and accurate medical reporting."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Emergency Services"
              secondary="Shared in life-threatening situations to ensure appropriate care."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Insurers"
              secondary="Information shared for claims processing and verification."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Regulators"
              secondary="Data provided during audits or investigations to ensure compliance."
            />
          </ListItem>
        </List>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" gutterBottom>
          Children's Data
        </Typography>
        <Typography variant="body1" paragraph>
          For patients under 18, parental or guardian consent is obtained for
          data processing. Once a minor reaches the age of maturity, they gain
          control over their personal data and may update or revoke consents
          previously given.
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" gutterBottom>
          Retention of Medical Data
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Patient Records"
              secondary="Retained for a minimum of 8 years, or longer for children and serious incidents."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Children’s Records"
              secondary="Retained until the patient’s 25th birthday or 8 years after their death, whichever is later."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Serious Incident Records"
              secondary="Retained indefinitely for legal and compliance purposes."
            />
          </ListItem>
        </List>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" gutterBottom>
          Backup Systems and Disaster Recovery
        </Typography>
        <Typography variant="body1" paragraph>
          To ensure the security and availability of personal data, DCEM employs
          Fasthosts Cyber Protect for robust backup and disaster recovery
          solutions. Key features include:
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Encrypted Backups"
              secondary="Backups are encrypted with AES-256 to protect data during transit and at rest."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Secure Data Centres"
              secondary="All data is stored in ISO 27001-certified data centres within the UK and EEA, featuring advanced security protocols."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Data Retention"
              secondary="Backup data is retained for 30 days and securely deleted thereafter, unless required for compliance or ongoing operations."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Disaster Recovery"
              secondary="Rapid restoration capabilities minimise downtime and ensure continuity in the event of a system failure."
            />
          </ListItem>
        </List>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" gutterBottom>
          Data Security
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="ISO Certification"
              secondary="Data stored in ISO 27001-certified centres ensures compliance with international security standards."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Encryption Standards"
              secondary="All sensitive data is encrypted using AES-256 protocols."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Access Controls"
              secondary="Multi-factor authentication and role-based permissions protect data access."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Penetration Testing"
              secondary="Regular tests are conducted to identify and mitigate security vulnerabilities."
            />
          </ListItem>
        </List>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" gutterBottom>
          Privacy Impact Assessments (PIAs)
        </Typography>
        <Typography variant="body1" paragraph>
          We conduct Privacy Impact Assessments (PIAs) for all new projects
          involving personal data. This process evaluates potential risks and
          implements mitigations to protect privacy and ensure compliance with
          UK GDPR.
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" gutterBottom>
          Incident and Breach Response
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Breach Detection"
              secondary="Systems are monitored to promptly identify and respond to data breaches."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Notification"
              secondary="The ICO is notified within 72 hours of a breach, and affected individuals are informed promptly."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Remediation"
              secondary="Steps are taken to mitigate damage and prevent future incidents."
            />
          </ListItem>
        </List>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" gutterBottom>
          International Data Transfers
        </Typography>
        <Typography variant="body1" paragraph>
          All data is stored within ISO-certified UK data centres. In cases
          where international transfers are necessary, they comply with GDPR and
          utilise Standard Contractual Clauses (SCCs) to ensure data protection.
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" gutterBottom>
          Monitoring and Auditing
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Regular Audits"
              secondary="Internal and external audits ensure ongoing compliance with regulatory standards."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Penetration Testing"
              secondary="Conducted regularly to evaluate and improve system security."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Policy Reviews"
              secondary="Our policies are reviewed and updated periodically to reflect best practices."
            />
          </ListItem>
        </List>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" gutterBottom>
          Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We regularly review and update this Privacy Policy to reflect changes
          in our practices, technology, or legal requirements. Significant
          updates will be communicated to affected individuals promptly.
        </Typography>
      </Paper>
    </Box>
  );
};

export default PrivacyPolicy;
