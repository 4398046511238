import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import apiClient from "../utils/requestHandler";
import { useLoading } from "../contexts/LoadingContext";
import { Box, CircularProgress } from "@mui/material";

function AuthLoader({ children }) {
  const [user, setUser] = useState(null);
  const [userLoaded, setUserLoaded] = useState(false);
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        const res = await apiClient.get("/api/auth/user", {
          withCredentials: true,
        });
        setUser(res.data);
      } catch {
        setUser(null);
      } finally {
        setLoading(false);
        setUserLoaded(true);
      }
    };

    if (!userLoaded) fetchUser();
  }, [userLoaded, setUser, setLoading]);

  useEffect(() => {
    if (userLoaded) {
      const publicRoutes = ["/login", "/patient-register","/createadmin"];
      const currentPath = location.pathname;

      // Redirect unauthenticated users to login if accessing protected routes
      if (!user && !publicRoutes.includes(currentPath)) {
        navigate("/login", { replace: true });
      }
      // Redirect authenticated users from public routes to their dashboard
      else if (user && publicRoutes.includes(currentPath)) {
        const target =
          user.role === "admin" || user.role === "staff"
            ? "/dashboard"
            : user.role === "client"
            ? "/client"
            : user.role === "patient"
            ? "/patient-access"
            : "/login"; // Default fallback
        navigate(target, { replace: true });
      }
    }
  }, [user, userLoaded, location.pathname, navigate]);

  if (!userLoaded) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return children;
}

export default AuthLoader;
