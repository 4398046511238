import React, { useState, useEffect } from "react";
import "./LoadingAnimation.css";
import { CircularProgress, Box, Typography } from "@mui/material";

const loadingComments = [
  "Dispatching medics to fix the internet...",
  "Hold tight, we're on a life-saving mission!",
  "Counting down heartbeats...",
  "Sirens on! Loading faster than you can say '999'!",
  "Administering data IV drip...",
  "Stand by – blue lights activated!",
  "Loading... just like waiting for an ambulance in rush hour!",
  "Medic on the way – loading like it’s an emergency!",
];

const getRandomComment = () => {
  return loadingComments[Math.floor(Math.random() * loadingComments.length)];
};

const LoadingScreen = () => {
  const [comment, setComment] = useState(getRandomComment());

  useEffect(() => {
    const interval = setInterval(() => {
      setComment(getRandomComment());
    }, 3000); // Change comment every 3 seconds

    return () => clearInterval(interval); // Clear interval on unmount
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "linear-gradient(135deg, #1a1a2e, #16213e)",
        color: "#fff",
        overflow: "hidden",
      }}
    >
      {/* Dynamic Pulsating Circle */}
      <Box
        sx={{
          position: "absolute",
          width: "250px",
          height: "250px",
          borderRadius: "50%",
          background: "rgba(0, 212, 255, 0.2)",
          boxShadow: "0 0 20px rgba(0, 212, 255, 0.7)",
          animation: "pulse 2s infinite ease-in-out",
        }}
      />

      {/* Ambulance Animation */}
      <Box
        className="road"
        sx={{
          position: "absolute",
          bottom: "20%",
          width: "100%",
          height: "10px",
          background: "linear-gradient(to right, #333, #444)",
        }}
      >
        <Box
          className="ambulance"
          sx={{
            position: "absolute",
            width: "50px",
            height: "30px",
            background: "#fff",
            borderRadius: "5px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
            animation: "drive 5s infinite linear",
          }}
        >
          <Box
            className="blue-light"
            sx={{
              position: "absolute",
              top: "-5px",
              left: "50%",
              width: "10px",
              height: "10px",
              background: "blue",
              borderRadius: "50%",
              animation: "blink 1s infinite",
            }}
          />
        </Box>
      </Box>

      {/* Loading Comment */}
      <Box
        sx={{
          position: "relative",
          textAlign: "center",
          zIndex: 10,
        }}
      >
        <CircularProgress
          sx={{
            color: "#00d4ff",
            animation: "rotate 1.5s linear infinite",
            marginBottom: "20px",
          }}
          size={60}
          thickness={5}
        />
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            textShadow: "0px 0px 10px rgba(0, 212, 255, 0.7)",
          }}
        >
          {comment}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            marginTop: "10px",
            color: "#ccc",
            textShadow: "0px 0px 5px rgba(255, 255, 255, 0.3)",
          }}
        >
          Please wait while we prepare everything for you...
        </Typography>
      </Box>
    </Box>
  );
};

export default LoadingScreen;
