import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useLoading } from "../contexts/LoadingContext";
import LoadingScreen from "../components/LoadingScreen"; // Ensure this points to your loading component

const PrivateRoute = ({ children, allowedRoles }) => {
  const { user, userLoaded } = useAuth();
  const { isLoading } = useLoading();

  // Show loading screen if user data isn't loaded yet
  if (isLoading || !userLoaded) {
    return <LoadingScreen />;
  }

  // Redirect to login if no user is logged in
  if (!user) {
    return <Navigate to="/login" />;
  }

  // Redirect based on role if the user's role is not allowed
  if (!allowedRoles.includes(user.role)) {
    switch (user.role) {
      case "client":
        return <Navigate to="/client" />;
      case "patient":
        return <Navigate to={`/patients/${user.patientId}`} />;
      default:
        return <Navigate to="/login" />;
    }
  }

  // Render the child components if the user's role is allowed
  return children;
};

export default PrivateRoute;
