import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  CircularProgress,
  Autocomplete,
  Divider,
} from "@mui/material";
import apiClient from "../../utils/requestHandler";
import useNetworkStatus from "../../hooks/useNetworkStatus";
import { usePRF } from "../../utils/PRFContext"; // Import the PRF context

const PatientDemographics = () => {
  const { prfData, setPrfData } = usePRF(); // Access PRF data and setter from context
  const [searchName, setSearchName] = useState("");
  const [searchDob, setSearchDob] = useState("");
  const [foundPatients, setFoundPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [error, setError] = useState("");
  const [cheerTeams, setCheerTeams] = useState([]); // Track available cheer teams
  const [selectedTeam, setSelectedTeam] = useState(""); // Selected cheer team
  const [selectedSquad, setSelectedSquad] = useState(""); // Selected squad
  const isOnline = useNetworkStatus();
  const [searchAddress, setSearchAddress] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const genders = [
    "Male",
    "Female",
    "Non-binary",
    "Genderqueer",
    "Prefer not to say",
    "Other",
  ];

  const handleAddressSearch = async () => {
    if (searchAddress.length > 3) {
      setIsSearching(true);
      try {
        const response = await apiClient.get(
          "https://api.opencagedata.com/geocode/v1/json",
          {
            params: {
              q: searchAddress,
              key: "29b83171db2646e6b9e90a3a3c62cc0a",
              countrycode: "GB",
              limit: 5,
            },
          }
        );

        const suggestions = response.data.results.map((result) => ({
          formatted: result.formatted,
          components: result.components,
        }));

        setAddressSuggestions(suggestions);
      } catch (err) {
        console.error("Address lookup failed", err);
        setAddressSuggestions([]);
      } finally {
        setIsSearching(false);
      }
    }
  };

  const handleAddressSelect = (selected) => {
    if (selected) {
      const { components } = selected;

      const addressParts = {
        addressLine1: components.road || "",
        addressLine2: components.suburb || "",
        addressLine3: components.neighbourhood || "",
        town: components.city || components.town || "",
        county: components.state || "",
        postcode: components.postcode || "",
      };

      const fullAddress = concatenateAddress(addressParts);

      setPrfData((prev) => ({
        ...prev,
        demographics: {
          ...prev.demographics,
          address: fullAddress, // Store concatenated address
          ...addressParts, // Keep individual fields for form interaction
        },
      }));
    }
  };

  // Fetch cheer teams and squads for the dropdown
  const fetchCheerTeams = async () => {
    try {
      const response = await apiClient.get(`/api/cheer/teams`); // Endpoint for cheer teams and squads
      setCheerTeams(response.data);
    } catch (error) {
      console.error("Error fetching cheer teams:", error);
    }
  };

  useEffect(() => {
    fetchCheerTeams(); // Fetch cheer teams on component mount
  }, []);

  // Populate form with existing PRF data if available and split the address
  useEffect(() => {
    if (
      prfData?.demographics?.patient_id &&
      prfData.demographics.patient_id !== ""
    ) {
      console.log("Loaded existing patient data:", prfData.demographics);
      setSelectedPatient(prfData.demographics);
      // Parse the address and update PRF context with split data
      const parsedAddress = parseAddress(prfData.demographics.address);
      setPrfData((prev) => ({
        ...prev,
        demographics: {
          ...prev.demographics,
          ...parsedAddress,
        },
      }));
    } else {
      console.log("No existing patient data found");
    }
  }, [prfData, setPrfData]);

  const handleSearchPatient = async () => {
    if (!isOnline) return;

    try {
      const response = await apiClient.get(
        `/api/patients/search?name=${searchName}&dob=${searchDob}`
      );
      setFoundPatients(response.data);
      setError("");
    } catch (err) {
      setError("No patient found. Please create a new patient.");
      setFoundPatients([]);
    }
  };

  const concatenateAddress = (addressParts) => {
    return [
      addressParts.addressLine1,
      addressParts.addressLine2,
      addressParts.addressLine3,
      addressParts.town,
      addressParts.county,
      addressParts.postcode,
    ]
      .filter((part) => part) // Filter out empty or undefined parts
      .join(", "); // Join parts with a comma and space
  };

  const handleCreateOrUpdatePatient = async () => {
    if (!isOnline) {
      setError(
        "You are offline. Please connect to the internet and try again."
      );
      return;
    }

    if (!prfData.demographics?.name || !prfData.demographics?.dob) {
      setError("Patient name and date of birth are required.");
      return;
    }

    try {
      setError(""); // Clear any existing errors
      setLoading(true); // Disable button to prevent duplicate submissions

      let response;
      if (selectedPatient?.patient_id) {
        // Update existing patient
        response = await apiClient.put(
          `/api/patient/${selectedPatient.patient_id}`,
          {
            ...prfData.demographics,
            cheer_team_id: selectedTeam,
            cheer_squad_id: selectedSquad,
          }
        );
        alert("Patient updated successfully.");
      } else {
        // Create new patient
        response = await apiClient.post("/api/patient", {
          ...prfData.demographics,
          cheer_team_id: selectedTeam,
          cheer_squad_id: selectedSquad,
        });

        setFoundPatients([response.data]); // Add the new patient to the search results
        setPrfData((prev) => ({
          ...prev,
          demographics: {
            ...prev.demographics,
            patient_id: response.data.patient_id,
          },
        }));
        alert("Patient created successfully.");
      }

      // Add the patient to the squad only if a squad is selected
      if (selectedSquad) {
        await apiClient.post("/api/patient/patient-squad", {
          patient_id: response.data.patient_id,
          squad_id: selectedSquad,
          start_date: new Date().toISOString(),
        });
      }

      setSelectedPatient(response.data); // Update the selected patient
    } catch (err) {
      console.error("Failed to save patient:", err);
      setError("Failed to save patient. Please try again.");
    } finally {
      setLoading(false); // Re-enable the button after the operation
    }
  };

  const handlePatientSelect = async (patient) => {
    setSelectedPatient(patient);

    // 1) Parse the address string into separate fields
    const { addressLine1, town, county, postcode } = parseAddress(
      patient.address
    );

    // Populate PRF data with selected patient details, including patient_id
    setPrfData((prev) => ({
      ...prev,
      demographics: {
        patient_id: patient.patient_id,
        name: patient.name,
        dob: patient.dob,
        gender: patient.gender,
        address: patient.address,
        contact_number: patient.contact_number,
        next_of_kin_name: patient.next_of_kin_name,
        next_of_kin_contact: patient.next_of_kin_contact,
        relationship_to_nok: patient.relationship_to_nok,
        past_medical_history: patient.past_medical_history,
        medications: patient.medications,
        allergies: patient.allergies,
        addressLine1,
        town,
        county,
        postcode,
      },
    }));

    // Assign the patient to the selected cheer squad
    if (selectedSquad === !"")
      await apiClient.post("/api/patient-squad", {
        patient_id: patient.patient_id,
        squad_id: selectedSquad,
        start_date: new Date().toISOString(),
      });
  };

  function parseAddress(addressString = "") {
    const parts = addressString.split(",").map((part) => part.trim());

    // The array might have 2, 3, or 4 items depending on how many commas exist.
    // We'll just destructure safely:
    const [part1, part2, part3, part4] = parts;

    // Decide how you want to map them to your fields.
    // Example:
    //  part1 => addressLine1
    //  part2 => town
    //  part3 => county (if present)
    //  part4 => postcode
    return {
      addressLine1: part1 || "",
      town: part2 || "",
      county: part3 || "",
      postcode: part4 || "",
    };
  }

  const handleInputChangeLocal = (e) => {
    const { name, value } = e.target;

    setPrfData((prev) => {
      const updatedDemographics = {
        ...prev.demographics,
        [name]: value,
      };

      // If any address part is updated, update the full address
      if (
        [
          "addressLine1",
          "addressLine2",
          "addressLine3",
          "town",
          "county",
          "postcode",
        ].includes(name)
      ) {
        updatedDemographics.address = concatenateAddress(updatedDemographics);
      }

      return {
        ...prev,
        demographics: updatedDemographics,
      };
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Extract only the date part (yyyy-MM-dd)
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Patient Search Section */}
      {isOnline &&
        (!selectedPatient || prfData.demographics.patient_id === "") && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Patient Search
            </Typography>
            <TextField
              label="Patient Name"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Date of Birth"
              type="date"
              value={searchDob}
              onChange={(e) => setSearchDob(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
              InputLabelProps={{ shrink: true }}
            />
            <Button
              onClick={handleSearchPatient}
              variant="contained"
              sx={{ mb: 2 }}
              disabled={!searchName || !searchDob}
            >
              Search
            </Button>
            {foundPatients.length > 0 && (
              <List>
                {foundPatients.map((patient) => (
                  <ListItem
                    button
                    key={patient.patient_id}
                    onClick={() => handlePatientSelect(patient)}
                  >
                    <ListItemText
                      primary={`${patient.name} - DOB: ${new Date(
                        patient.dob
                      ).toLocaleDateString()}`}
                      secondary={`Contact: ${patient.contact_number}`}
                    />
                  </ListItem>
                ))}
              </List>
            )}
            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
          </Box>
        )}

      <Divider />

      {/* Cheer Team and Squad Selection */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Cheer Team and Squad
        </Typography>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="cheer-team-label">Cheer Team</InputLabel>
          <Select
            labelId="cheer-team-label"
            value={selectedTeam}
            label="Cheer Team"
            onChange={(e) => setSelectedTeam(e.target.value)}
          >
            {cheerTeams.map((team) => (
              <MenuItem key={team.team_id} value={team.team_id}>
                {team.team_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedTeam && (
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="cheer-squad-label">Cheer Squad</InputLabel>
            <Select
              labelId="cheer-squad-label"
              value={selectedSquad}
              label="Cheer Squad"
              onChange={(e) => setSelectedSquad(e.target.value)}
            >
              {cheerTeams
                .find((team) => team.team_id === selectedTeam)
                ?.squads.map((squad) => (
                  <MenuItem key={squad.squad_id} value={squad.squad_id}>
                    {squad.squad_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </Box>

      <Divider />

      {/* Patient Details Section */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Patient Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Patient Name"
              name="name"
              value={prfData.demographics?.name || ""}
              onChange={handleInputChangeLocal}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Date of Birth"
              name="dob"
              type="date"
              value={
                prfData.demographics?.dob
                  ? formatDate(prfData.demographics.dob)
                  : ""
              }
              onChange={handleInputChangeLocal}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="gender-select-label">Gender</InputLabel>
              <Select
                labelId="gender-select-label"
                name="gender"
                value={prfData.demographics?.gender || ""}
                onChange={handleInputChangeLocal}
              >
                {genders.map((gender) => (
                  <MenuItem key={gender} value={gender}>
                    {gender}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Address
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="searchAddress"
              label="Search Address"
              value={searchAddress}
              onChange={(e) => setSearchAddress(e.target.value)}
              fullWidth
            />
            <Button
              variant="contained"
              color="primary"
              disabled={isSearching || searchAddress.length < 3}
              onClick={handleAddressSearch}
              sx={{ mt: 2 }}
            >
              {isSearching ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Search"
              )}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              options={addressSuggestions.map((s) => s.formatted)}
              renderInput={(params) => (
                <TextField {...params} label="Address Suggestions" />
              )}
              onChange={(e, value) => {
                const selected = addressSuggestions.find(
                  (s) => s.formatted === value
                );
                if (selected) handleAddressSelect(selected);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="addressLine1"
              label="Address Line 1"
              name="addressLine1"
              value={prfData.demographics?.addressLine1 || ""}
              onChange={handleInputChangeLocal}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="town"
              label="Town"
              name="town"
              value={prfData.demographics?.town || ""}
              onChange={handleInputChangeLocal}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="county"
              label="County"
              name="county"
              value={prfData.demographics?.county || ""}
              onChange={handleInputChangeLocal}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="postcode"
              label="Postcode"
              name="postcode"
              value={prfData.demographics?.postcode || ""}
              onChange={handleInputChangeLocal}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Contact Number"
              name="contact_number"
              value={prfData.demographics?.contact_number || ""}
              onChange={handleInputChangeLocal}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ mb: 4 }} />

      {/* Emergency Contact */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Emergency Contact
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Next of Kin Name"
              name="next_of_kin_name"
              value={prfData.demographics?.next_of_kin_name || ""}
              onChange={handleInputChangeLocal}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Next of Kin Contact"
              name="next_of_kin_contact"
              value={prfData.demographics?.next_of_kin_contact || ""}
              onChange={handleInputChangeLocal}
              fullWidth
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Relationship to Next of Kin"
              name="relationship_to_nok"
              value={prfData.demographics?.relationship_to_nok || ""}
              onChange={handleInputChangeLocal}
              fullWidth
              sx={{ mb: 2 }}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ mb: 4 }} />

      {/* Medical History */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Medical History
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Past Medical History"
              name="past_medical_history"
              value={prfData.demographics?.past_medical_history || ""}
              onChange={handleInputChangeLocal}
              fullWidth
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Medications"
              name="medications"
              value={prfData.demographics?.medications || ""}
              onChange={handleInputChangeLocal}
              fullWidth
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Allergies"
              name="allergies"
              value={prfData.demographics?.allergies || ""}
              onChange={handleInputChangeLocal}
              fullWidth
              sx={{ mb: 2 }}
            />
          </Grid>
        </Grid>
        {/* Only one button, but it changes label and behavior based on patient_id */}
        {!prfData.demographics.patient_id ? (
          <Button
            onClick={handleCreateOrUpdatePatient}
            variant="contained"
            disabled={loading}
            sx={{ mt: 2 }}
          >
            {loading ? "Processing..." : "Create Patient"}
          </Button>
        ) : (
          <Button
            onClick={handleCreateOrUpdatePatient}
            variant="contained"
            disabled={loading}
            sx={{ mt: 2 }}
          >
            {loading ? "Processing..." : "Update Patient"}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default PatientDemographics;
