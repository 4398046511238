import React, { createContext, useContext, useState, useEffect } from "react";
import apiClient from "../utils/requestHandler";
import { useLoading } from "./LoadingContext";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userLoaded, setUserLoaded] = useState(false);
  const { setLoading } = useLoading();

  const fetchUser = async () => {
    setLoading(true);
    try {
      const res = await apiClient.get("/api/auth/user", {
        withCredentials: true,
      });
      setUser(res.data);
    } catch (error) {
      console.error("AuthProvider - Failed to fetch user:", error);
      setUser(null); // Explicitly clear user state
    } finally {
      setLoading(false);
      setUserLoaded(true); // Ensure `userLoaded` is set regardless of outcome
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    console.log("AuthProvider - Auth state updated:", { user, userLoaded });
  }, [user, userLoaded]);

  const authContextValue = {
    user,
    setUser,
    userLoaded,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
