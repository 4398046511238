import React from "react";
import { Modal, Typography, Button, Box, Divider } from "@mui/material";

const GDPRModal = ({ open, onAgree, gdprContent }) => {
  return (
    <Modal open={open} onClose={() => {}}>
      <Box
        sx={{
          maxWidth: 700,
          bgcolor: "background.paper",
          p: 4,
          mx: "auto",
          mt: 10,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            mb: 3,
            fontWeight: "bold",
            textAlign: "center",
            color: "primary.main",
          }}
        >
          Privacy Notice
        </Typography>
        <Divider sx={{ mb: 3 }} />
        <Box
          sx={{
            maxHeight: "50vh",
            overflowY: "auto",
            mb: 4,
            pr: 2, // Add padding for better scroll experience
          }}
        >
          {gdprContent.split("\n").map((paragraph, index) => (
            <Typography
              key={index}
              variant="body1"
              sx={{
                mb: 2,
                lineHeight: 1.6,
                textAlign: "justify",
                color: "text.primary",
              }}
            >
              {paragraph}
            </Typography>
          ))}
        </Box>
        <Divider sx={{ mb: 3 }} />
        <Button
          variant="contained"
          color="primary"
          onClick={onAgree}
          fullWidth
          sx={{
            py: 1.5,
            fontSize: "1rem",
            textTransform: "none",
          }}
        >
          I Agree
        </Button>
      </Box>
    </Modal>
  );
};

export default GDPRModal;
