import React, { createContext, useContext, useState, useEffect } from "react";
import LoadingScreen from "../components/LoadingScreen";

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("LoadingProvider - Loading state:", loading);
  }, [loading]);

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      <>
        {loading && <LoadingScreen />}
        {children}
      </>
    </LoadingContext.Provider>
  );
};

// Custom hook to access loading context
export const useLoading = () => useContext(LoadingContext);
