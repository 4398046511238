// src/components/ErrorBoundary.js
import React, { Component } from "react";
import { Typography, Button, Box } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    console.error("Error caught by ErrorBoundary:", error, info);
  }

  handleReload = () => {
    this.setState({ hasError: false, error: null });
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            textAlign: "center",
            padding: 3,
            backgroundColor: "#f9f9f9",
          }}
        >
          <ErrorOutlineIcon sx={{ fontSize: 80, color: "#d32f2f" }} />
          <Typography variant="h4" color="error" gutterBottom>
            Oops! Something went sideways.
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ maxWidth: 600, marginBottom: 2 }}
          >
            {this.state.error?.message ||
              "An unexpected error occurred. Our system is working as hard as a coffee-deprived intern to fix it."}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ maxWidth: 600, marginBottom: 4 }}
          >
            "When in doubt, turn it off and on again." But seriously, try
            refreshing the page.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleReload}
            startIcon={<SentimentDissatisfiedIcon />}
            sx={{ padding: "10px 20px" }}
          >
            Refresh and Hope for the Best
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
