import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import apiClient from "../utils/requestHandler"; // Axios instance for API calls

const localizer = momentLocalizer(moment);

const ShiftCalendar = () => {
  /****************************************
   * 1) FETCH CURRENT USER & STAFF
   ****************************************/
  const [currentUser, setCurrentUser] = useState(null);
  const [staff, setStaff] = useState([]);

  /****************************************
   * SHIFT & EVENT STATE
   ****************************************/
  const [shifts, setShifts] = useState([]);
  const [events, setEvents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedShift, setSelectedShift] = useState(null);

  /****************************************
   * SHIFT CREATION OBJECT
   ****************************************/
  const [newShift, setNewShift] = useState({
    eventID: "",
    recurrence: "none",
    priority: "normal", // (4) Priority or escalation logic
    briefing: "",       // (8) Detailed event/shift info
    shifts: [
      {
        startTime: "",
        endTime: "",
        role: "",
        requiredQualifications: [],
        // (6) Backup Staff Slot?
        // backupEnabled: false,
      },
    ],
  });

  /****************************************
   * SHIFT NOTES
   ****************************************/
  const [shiftNotes, setShiftNotes] = useState([]);
  const [newNote, setNewNote] = useState("");

  /****************************************
   * 2) TIME AND ATTENDANCE TRACKING
   *    We’ll store check-in and check-out times in shift state.
   ****************************************/
  const handleCheckIn = async (shiftId) => {
    try {
      await apiClient.post(`/api/shifts/${shiftId}/checkin`, {
        checkInTime: new Date(),
      });
      // Possibly re-fetch shifts or update local state with check-in data
      fetchShifts();
    } catch (err) {
      console.error("Error checking in:", err);
    }
  };

  const handleCheckOut = async (shiftId) => {
    try {
      await apiClient.post(`/api/shifts/${shiftId}/checkout`, {
        checkOutTime: new Date(),
      });
      // Possibly re-fetch shifts or update local state with check-out data
      fetchShifts();
    } catch (err) {
      console.error("Error checking out:", err);
    }
  };

  /****************************************
   * 3) AVAILABILITY AND SHIFT BIDDING
   *    If a shift is "open," staff can bid/apply for it.
   ****************************************/
  const handleApplyForShift = async (shiftId) => {
    try {
      await apiClient.post(`/api/shifts/${shiftId}/apply`, {
        userId: currentUser.id,
      });
      // The shift could then go into a "bidding" or "pending" state
      fetchShifts();
    } catch (err) {
      console.error("Error applying for shift:", err);
    }
  };

  /****************************************
   * 4) PRIORITY OR ESCALATION LOGIC
   *    We add a 'priority' field to event or shift creation.
   ****************************************/
  // In the UI, newShift.priority is set via a dropdown (low, normal, high, critical).
  // This can affect color-coding or required staff counts, etc.

  /****************************************
   * 5) GEO-LOCATION & PROXIMITY
   *    Pseudo-logic: we won't implement an actual geocoding step here,
   *    but show how you might filter staff by location.
   ****************************************/
  const filterStaffByProximity = (eventLocation) => {
    // For demonstration:
    // 1) Each staff might have "lat" & "lng" in their profile.
    // 2) Compare distance from the eventLocation to staff location.
    // 3) Return array of staff sorted by nearest first.
    return staff;
  };

  /****************************************
   * 6) EMERGENCY COVERAGE / BACKUP STAFF
   *    Example: If shift is marked with "backupEnabled = true",
   *    we can add a second staff assignment for that shift.
   ****************************************/
  const handleAssignBackupStaff = async (shiftId, backupStaffId) => {
    try {
      await apiClient.post(`/api/shifts/${shiftId}/assign-backup`, {
        staffId: backupStaffId,
      });
      fetchShifts();
    } catch (err) {
      console.error("Error assigning backup staff:", err);
    }
  };

  /****************************************
   * 7) AUTOMATED NOTIFICATIONS & REMINDERS
   *    E.g., backend cron job or third-party service
   *    sending an email/SMS 24 hours before shift.
   ****************************************/
  // Example placeholders for enabling reminders
  const handleEnableReminders = async (shiftId) => {
    try {
      await apiClient.post(`/api/shifts/${shiftId}/reminders`, {
        reminderSchedule: ["24h", "1h"], // e.g., 24 hours and 1 hour prior
      });
      alert("Reminders enabled for this shift!");
    } catch (err) {
      console.error("Error enabling reminders:", err);
    }
  };

  /****************************************
   * 8) DETAILED EVENT & SHIFT INFORMATION
   *    We have a 'briefing' field in newShift,
   *    which can store crowd size, hazards, etc.
   ****************************************/

  /****************************************
   * 9) MOBILE-FRIENDLY / TABLET KIOSK MODE
   *    Implementation tip:
   *    - Use responsive design.
   *    - Provide "Quick Actions" (check in, check out, add note, etc.)
   ****************************************/

  /****************************************
   * 10) REPORTING AND DATA ANALYTICS
   *    We'll just show a basic example of retrieving data for a report.
   ****************************************/
  const generateShiftReport = async () => {
    try {
      const res = await apiClient.get("/api/shifts/report");
      // This might return shift coverage stats, hours, staff usage, etc.
      console.log("Shift report data:", res.data);
    } catch (err) {
      console.error("Error generating shift report:", err);
    }
  };

  // ============================= FETCH DATA ==============================
  const fetchCurrentUser = async () => {
    try {
      const res = await apiClient.get("/api/auth/user");
      setCurrentUser(res.data);
    } catch (err) {
      console.error("Error fetching current user:", err);
    }
  };

  const fetchShifts = async () => {
    try {
      const response = await apiClient.get("/api/shifts");
      setShifts(
        response.data.map((shift) => ({
          title: `Shift (${shift.role || "Unassigned"})`,
          start: new Date(shift.shiftStart),
          end: new Date(shift.shiftEnd),
          role: shift.role,
          id: shift.shiftID,
          status: shift.status,
          staff: shift.staff || null, 
          requiredQualifications: shift.requiredQualifications || [],
          priority: shift.priority || "normal",
          briefing: shift.briefing || "",
          checkInTime: shift.checkInTime || null, 
          checkOutTime: shift.checkOutTime || null, 
          isBiddingOpen: shift.isBiddingOpen || false,
          applicants: shift.applicants || [],  // (3) Shift Bidding
          backupStaff: shift.backupStaff || null,  // (6) Backup staff
        }))
      );
    } catch (err) {
      console.error("Error fetching shifts:", err);
    }
  };

  const fetchEvents = async () => {
    try {
      const response = await apiClient.get("/api/events");
      setEvents(response.data);
    } catch (err) {
      console.error("Error fetching events:", err);
    }
  };

  const fetchStaff = async () => {
    try {
      const response = await apiClient.get("/api/staff");
      setStaff(response.data);
    } catch (err) {
      console.error("Error fetching staff:", err);
    }
  };

  const fetchShiftNotes = async (shiftId) => {
    try {
      const response = await apiClient.get(`/api/shifts/${shiftId}/notes`);
      setShiftNotes(response.data);
    } catch (err) {
      console.error("Error fetching shift notes:", err);
      setShiftNotes([]);
    }
  };

  // ============================= USE EFFECT ==============================
  useEffect(() => {
    fetchCurrentUser();
    fetchShifts();
    fetchEvents();
    fetchStaff();
  }, []);

  // ============================= HANDLERS ==============================
  const handleSelectShift = async (event) => {
    const selected = shifts.find((shift) => shift.id === event.id);
    setSelectedShift(selected);
    await fetchShiftNotes(event.id);
    setModalOpen(true);
  };

  const handleAddNote = async () => {
    if (!newNote.trim() || !selectedShift) return;
    try {
      await apiClient.post(`/api/shifts/${selectedShift.id}/notes`, {
        note: newNote,
      });
      setNewNote("");
      await fetchShiftNotes(selectedShift.id);
    } catch (err) {
      console.error("Error adding note:", err);
    }
  };

  const handleEventDrop = async ({ event, start, end }) => {
    try {
      await apiClient.put(`/api/shifts/${event.id}`, {
        shiftStart: start,
        shiftEnd: end,
      });
      fetchShifts();
    } catch (err) {
      console.error("Error dropping shift:", err);
    }
  };

  const handleEventResize = async ({ event, start, end }) => {
    try {
      await apiClient.put(`/api/shifts/${event.id}`, {
        shiftStart: start,
        shiftEnd: end,
      });
      fetchShifts();
    } catch (err) {
      console.error("Error resizing shift:", err);
    }
  };

  const handleAddShiftRow = () => {
    setNewShift((prev) => ({
      ...prev,
      shifts: [
        ...prev.shifts,
        {
          startTime: "",
          endTime: "",
          role: "",
          requiredQualifications: [],
          // backupEnabled: false,
        },
      ],
    }));
  };

  const handleAddShift = async () => {
    if (!newShift.eventID) {
      alert("Please select an event before adding shifts.");
      return;
    }
    const { shifts: shiftArray, recurrence, priority, briefing } = newShift;
    try {
      await apiClient.post("/api/shifts/batchCreate", {
        eventId: newShift.eventID,
        shifts: shiftArray,
        recurrence,
        priority,
        briefing,
      });
      setModalOpen(false);
      fetchShifts();
    } catch (err) {
      console.error("Error adding shift(s):", err);
      alert("There was an issue creating the shift(s). Please try again.");
    }
  };

  const handleAssignStaff = async (selectedStaffID) => {
    if (!selectedShift) return;
    try {
      // Basic role-based check
      if (currentUser?.role !== "admin") {
        // Only self-assign is allowed
        if (parseInt(selectedStaffID, 10) !== currentUser?.id) {
          alert("You do not have permission to assign other staff.");
          return;
        }
      }
      // Check qualifications, etc., as before ...
      await apiClient.put(`/api/shifts/${selectedShift.id}/assign`, {
        staffId: selectedStaffID,
        status: "assigned",
      });
      setSelectedShift(null);
      setModalOpen(false);
      fetchShifts();
    } catch (err) {
      console.error("Error assigning staff:", err);
    }
  };

  const handleDelete = async () => {
    if (!selectedShift) return;
    try {
      await apiClient.delete(`/api/shifts/${selectedShift.id}`);
      setModalOpen(false);
      setSelectedShift(null);
      fetchShifts();
    } catch (err) {
      console.error("Error deleting shift:", err);
      alert("Failed to delete the shift. Please try again.");
    }
  };

  const handleCloneShift = async (shiftToClone) => {
    if (!shiftToClone) return;
    try {
      await apiClient.post("/api/shifts/clone", {
        shiftId: shiftToClone.id,
      });
      fetchShifts();
      alert("Shift cloned successfully!");
    } catch (err) {
      console.error("Error cloning shift:", err);
    }
  };

  // ============================= BIG-CALENDAR CONFIG ==============================
  const roles = [
    "Paramedic",
    "Team Leader",
    "Nurse",
    "Doctor",
    "Medical Director",
    "First Responder",
    "Medic",
    "First Aider",
    "Technician",
    "Advanced Medic",
    "Trainer",
    "Paediatric Medic",
    "Support Staff",
  ];

  // Example qualifications:
  const allQualifications = [
    "FREC Level 3",
    "FREC Level 4 (First Response Emergency Care)",
    "ALS",
    "BLS",
    "Trauma Care",
  ];

  // Priority levels for (4) Escalation Logic:
  const priorityLevels = ["low", "normal", "high", "critical"];

  const eventPropGetter = (event) => {
    let backgroundColor;
    switch (event.priority) {
      case "low":
        backgroundColor = "#5bc0de"; // light-blue
        break;
      case "normal":
        backgroundColor = "#5cb85c"; // green
        break;
      case "high":
        backgroundColor = "#f0ad4e"; // orange
        break;
      case "critical":
        backgroundColor = "#d9534f"; // red
        break;
      default:
        backgroundColor = "#777";
    }
    return {
      style: { backgroundColor, color: "#fff", borderRadius: "5px" },
    };
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Shift Calendar
      </Typography>
      <Calendar
        localizer={localizer}
        events={shifts}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        selectable
        onSelectEvent={handleSelectShift}
        eventPropGetter={eventPropGetter}
        onEventDrop={handleEventDrop}
        onEventResize={handleEventResize}
        resizable
        views={["month", "week", "day"]}
        onSelectSlot={(slotInfo) => {
          setModalOpen(true);
          setNewShift((prev) => ({
            ...prev,
            shifts: [
              {
                ...prev.shifts[0],
                startTime: slotInfo.start,
                endTime: slotInfo.end,
              },
            ],
          }));
        }}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={() => setModalOpen(true)}
        sx={{ mt: 3 }}
      >
        Add New Shift(s)
      </Button>

      {/* Example of a button to generate a shift report (10) */}
      <Button
        variant="outlined"
        sx={{ mt: 3, ml: 2 }}
        onClick={generateShiftReport}
      >
        Generate Shift Report
      </Button>

      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setSelectedShift(null);
          setShiftNotes([]);
          setNewShift({
            eventID: "",
            recurrence: "none",
            priority: "normal",
            briefing: "",
            shifts: [
              {
                startTime: "",
                endTime: "",
                role: "",
                requiredQualifications: [],
              },
            ],
          });
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            borderRadius: 2,
            p: 3,
          }}
        >
          {selectedShift ? (
            <>
              <Typography variant="h6">Shift Details</Typography>
              <Typography variant="body1">
                Title: {selectedShift.title}
              </Typography>
              <Typography variant="body1">
                Start: {moment(selectedShift.start).format("LLLL")}
              </Typography>
              <Typography variant="body1">
                End: {moment(selectedShift.end).format("LLLL")}
              </Typography>
              <Typography variant="body1">Status: {selectedShift.status}</Typography>
              <Typography variant="body1">
                Priority: {selectedShift.priority}
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Assigned Staff:{" "}
                {selectedShift.staff
                  ? `${selectedShift.staff.user.firstName} ${selectedShift.staff.user.lastName}`
                  : "Unassigned"}
              </Typography>

              {selectedShift.requiredQualifications?.length > 0 && (
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <strong>Required Qualifications:</strong>{" "}
                  {selectedShift.requiredQualifications.join(", ")}
                </Typography>
              )}

              {/* 8) Detailed Shift Briefing */}
              {selectedShift.briefing && (
                <Typography variant="body2" sx={{ mb: 2 }}>
                  <strong>Briefing:</strong> {selectedShift.briefing}
                </Typography>
              )}

              <Typography variant="h6">Shift Notes</Typography>
              {shiftNotes.length > 0 ? (
                shiftNotes.map((note, index) => (
                  <Typography key={index} variant="body2" sx={{ mb: 1 }}>
                    - {note.note}
                  </Typography>
                ))
              ) : (
                <Typography variant="body2" sx={{ fontStyle: "italic" }}>
                  No notes available.
                </Typography>
              )}

              <TextField
                label="Add Note"
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
                fullWidth
                sx={{ mt: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddNote}
                sx={{ mt: 2, mr: 2 }}
              >
                Add Note
              </Button>

              <Button
                variant="contained"
                color="info"
                onClick={() => handleCloneShift(selectedShift)}
                sx={{ mt: 2, mr: 2 }}
              >
                Clone Shift
              </Button>

              {/* 3) Shift Bidding / Apply button 
                  If shift is open for bidding, staff can apply. */}
              {selectedShift.isBiddingOpen && currentUser?.role !== "admin" && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => handleApplyForShift(selectedShift.id)}
                  sx={{ mt: 2, mr: 2 }}
                >
                  Apply for Shift
                </Button>
              )}

              {/* 4) Assign Staff: Only if admin, or staff for self-assign */}
              {currentUser?.role === "admin" ? (
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="staff-select-label">Assign Staff</InputLabel>
                  <Select
                    labelId="staff-select-label"
                    value=""
                    onChange={(e) => handleAssignStaff(e.target.value)}
                  >
                    {staff.map((member) => (
                      <MenuItem key={member.staffID} value={member.staffID}>
                        {`${member.user.firstName} ${member.user.lastName}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                !selectedShift.staff && (
                  <Button
                    variant="contained"
                    sx={{ mt: 2 }}
                    onClick={() => handleAssignStaff(currentUser?.id)}
                  >
                    Self-Assign
                  </Button>
                )
              )}

              {/* 2) Time & Attendance Buttons: Check In / Check Out */}
              {!selectedShift.checkInTime && (
                <Button
                  variant="contained"
                  sx={{ mt: 2, ml: 2 }}
                  onClick={() => handleCheckIn(selectedShift.id)}
                >
                  Check In
                </Button>
              )}
              {selectedShift.checkInTime && !selectedShift.checkOutTime && (
                <Button
                  variant="contained"
                  sx={{ mt: 2, ml: 2 }}
                  onClick={() => handleCheckOut(selectedShift.id)}
                >
                  Check Out
                </Button>
              )}

              {/* 6) Backup Staff Assign */}
              {/* If the shift supports backup staff, show a second staff select */}
              {currentUser?.role === "admin" && selectedShift.backupStaff === null && (
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="backup-staff-select">Assign Backup Staff</InputLabel>
                  <Select
                    labelId="backup-staff-select"
                    value=""
                    onChange={(e) =>
                      handleAssignBackupStaff(selectedShift.id, e.target.value)
                    }
                  >
                    {staff.map((member) => (
                      <MenuItem key={member.staffID} value={member.staffID}>
                        {`${member.user.firstName} ${member.user.lastName}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {/* 7) Enable Reminders */}
              <Button
                variant="outlined"
                sx={{ mt: 2, mr: 2 }}
                onClick={() => handleEnableReminders(selectedShift.id)}
              >
                Enable Reminders
              </Button>

              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setSelectedShift(null);
                  setModalOpen(false);
                }}
                sx={{ mt: 2, mr: 2 }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleDelete}
                sx={{ mt: 2 }}
              >
                Delete
              </Button>
            </>
          ) : (
            <>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Add New Shift(s)
              </Typography>
              {/* Select Event */}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="event-select-label">Event</InputLabel>
                <Select
                  labelId="event-select-label"
                  value={newShift.eventID}
                  onChange={(e) =>
                    setNewShift({ ...newShift, eventID: e.target.value })
                  }
                  fullWidth
                >
                  {events.map((event) => (
                    <MenuItem key={event.eventID} value={event.eventID}>
                      {event.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Recurrence Selector */}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="recurrence-label">Recurrence</InputLabel>
                <Select
                  labelId="recurrence-label"
                  value={newShift.recurrence}
                  onChange={(e) =>
                    setNewShift({ ...newShift, recurrence: e.target.value })
                  }
                >
                  <MenuItem value="none">None</MenuItem>
                  <MenuItem value="daily">Daily</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                </Select>
              </FormControl>

              {/* 4) Priority */}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="priority-label">Priority</InputLabel>
                <Select
                  labelId="priority-label"
                  value={newShift.priority}
                  onChange={(e) =>
                    setNewShift({ ...newShift, priority: e.target.value })
                  }
                >
                  {priorityLevels.map((level) => (
                    <MenuItem key={level} value={level}>
                      {level.charAt(0).toUpperCase() + level.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* 8) Detailed Shift Briefing */}
              <TextField
                label="Shift Briefing"
                multiline
                rows={3}
                value={newShift.briefing}
                onChange={(e) => setNewShift({ ...newShift, briefing: e.target.value })}
                fullWidth
                sx={{ mb: 2 }}
              />

              {newShift.shifts.map((shiftItem, index) => (
                <Box key={index} sx={{ mb: 2, border: "1px solid #ccc", p: 2 }}>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    Shift #{index + 1}
                  </Typography>
                  <TextField
                    label="Start Time"
                    type="datetime-local"
                    value={shiftItem.startTime}
                    onChange={(e) => {
                      const updatedShifts = [...newShift.shifts];
                      updatedShifts[index].startTime = e.target.value;
                      setNewShift({ ...newShift, shifts: updatedShifts });
                    }}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="End Time"
                    type="datetime-local"
                    value={shiftItem.endTime}
                    onChange={(e) => {
                      const updatedShifts = [...newShift.shifts];
                      updatedShifts[index].endTime = e.target.value;
                      setNewShift({ ...newShift, shifts: updatedShifts });
                    }}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id={`role-label-${index}`}>Role</InputLabel>
                    <Select
                      labelId={`role-label-${index}`}
                      value={shiftItem.role}
                      onChange={(e) => {
                        const updatedShifts = [...newShift.shifts];
                        updatedShifts[index].role = e.target.value;
                        setNewShift({ ...newShift, shifts: updatedShifts });
                      }}
                    >
                      {roles.map((role) => (
                        <MenuItem key={role} value={role}>
                          {role}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id={`req-qual-label-${index}`}>
                      Required Qualifications
                    </InputLabel>
                    <Select
                      labelId={`req-qual-label-${index}`}
                      multiple
                      value={shiftItem.requiredQualifications}
                      onChange={(e) => {
                        const updatedShifts = [...newShift.shifts];
                        updatedShifts[index].requiredQualifications =
                          e.target.value;
                        setNewShift({ ...newShift, shifts: updatedShifts });
                      }}
                    >
                      {allQualifications.map((qual) => (
                        <MenuItem key={qual} value={qual}>
                          {qual}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* 6) Backup Staff Slot? 
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={shiftItem.backupEnabled}
                            onChange={(e) => {
                              const updatedShifts = [...newShift.shifts];
                              updatedShifts[index].backupEnabled = e.target.checked;
                              setNewShift({ ...newShift, shifts: updatedShifts });
                            }}
                          />
                        }
                        label="Enable Backup Staff"
                      />
                  */}
                </Box>
              ))}

              <Button variant="outlined" onClick={handleAddShiftRow}>
                + Add Another Shift
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddShift}
                sx={{ mt: 2, ml: 2 }}
              >
                Save Shift(s)
              </Button>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default ShiftCalendar;
