import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import GDPRModal from "./GDPRModal";
import PatientDemographics from "./PatientDemographics";
import apiClient from "../../utils/requestHandler";

const KioskMode = () => {
  const [gdprModalOpen, setGdprModalOpen] = useState(true);
  const [gdprContent, setGdprContent] = useState("");
  const { prfId } = useParams(); // Ensure `prfId` comes from the correct source

  useEffect(() => {
    const fetchGDPRContent = async () => {
      try {
        const response = await apiClient.get("/api/gdpr-notice/active");
        setGdprContent(
          response.data.content || "GDPR notice content not found."
        );
      } catch (err) {
        setGdprContent("Failed to load GDPR notice content.");
      }
    };
    fetchGDPRContent();
  }, []);

  const handleAgree = async () => {
    setGdprModalOpen(false);
    await apiClient.post(`/api/prf/${prfId}/consent`, { consentGiven: true });
  };

  const handleSubmit = async () => {
    await apiClient.put(`/api/prf/${prfId}/kiosk/disable`);
    window.location.reload(); // Return to PRF details
  };

  return (
    <Box sx={{ p: 4, backgroundColor: "#f9f9f9", minHeight: "100vh" }}>
      <Typography variant="h4" sx={{ mb: 4, textAlign: "center" }}>
        Patient Information
      </Typography>
      {gdprModalOpen ? (
        <GDPRModal
          open={gdprModalOpen}
          onAgree={handleAgree}
          gdprContent={gdprContent}
        />
      ) : (
        <Box>
          <PatientDemographics />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            sx={{ mt: 4 }}
          >
            Submit and Exit
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default KioskMode;
