import React from "react";
import { Box, Link, Typography, Container, IconButton } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useAuth } from "../contexts/AuthContext";

// Get styles based on user role
const getFooterStyles = (role) => {
  const styles = {
    admin: { bg: "#1976d2", color: "#fff" },
    staff: { bg: "#2196f3", color: "#fff" },
    client: { bg: "#9c27b0", color: "#fff" },
    patient: { bg: "#4caf50", color: "#fff" },
    default: { bg: "#2c2c2c", color: "#fff" },
  };
  return styles[role] || styles.default;
};

const Footer = () => {
  const { user } = useAuth();
  const footerStyles = getFooterStyles(user?.role);

  return (
    <Box
      component="footer"
      sx={{
        py: 4,
        px: 2,
        mt: "auto",
        background: (theme) =>
          `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
        color: (theme) => theme.palette.primary.contrastText,
        transition: "background-color 0.5s ease, color 0.5s ease",
      }}
    >
      <Container maxWidth="md">
        <Typography variant="body1" align="center">
          © {new Date().getFullYear()} DC Event Medical
        </Typography>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Navigation Links */}
          <Box sx={{ display: "flex", justifyContent: "center", gap: 3 }}>
            <Link
              component={RouterLink}
              to="/privacy-policy"
              variant="body2"
              color="inherit"
              underline="hover"
            >
              Privacy Policy
            </Link>
            <Link
              component={RouterLink}
              to="/terms-of-service"
              variant="body2"
              color="inherit"
              underline="hover"
            >
              Terms of Service
            </Link>
            <Link
              component={RouterLink}
              to="/cookie-policy"
              variant="body2"
              color="inherit"
              underline="hover"
            >
              Cookie Policy
            </Link>
            <Link
              component={RouterLink}
              to="/contact-us"
              variant="body2"
              color="inherit"
              underline="hover"
            >
              Contact Us
            </Link>
          </Box>

          {/* Contact Information */}
          <Box
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="body2">For support or inquiries:</Typography>
            <Typography variant="body2">
              Email:{" "}
              <Link
                href="mailto:enquiries@dceventmedical.co.uk"
                color="inherit"
                underline="hover"
              >
                enquiries@dceventmedical.co.uk
              </Link>
            </Typography>
            <Typography variant="body2">
              Phone:{" "}
              <Link href="tel:+443300588348" color="inherit" underline="hover">
                03300 588 348
              </Link>
            </Typography>
          </Box>

          {/* Social Media Icons */}
          <Box sx={{ mt: 2, display: "flex", gap: 1 }}>
            <IconButton
              href="https://www.facebook.com/dceventmedical"
              target="_blank"
              rel="noopener"
              aria-label="Facebook"
              sx={{ color: "inherit" }}
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              href="https://twitter.com/dceventmedical"
              target="_blank"
              rel="noopener"
              aria-label="Twitter"
              sx={{ color: "inherit" }}
            >
              <TwitterIcon />
            </IconButton>
            <IconButton
              href="https://www.instagram.com/dceventmedical"
              target="_blank"
              rel="noopener"
              aria-label="Instagram"
              sx={{ color: "inherit" }}
            >
              <InstagramIcon />
            </IconButton>
            {/* Add other social media icons if needed */}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
