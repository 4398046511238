import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Grid,
  Box,
  Typography,
  Paper,
  Button,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  InputAdornment,
  MenuItem,
  Collapse,
} from "@mui/material";
import {
  AddCircleOutline,
  Search,
  Edit,
  Delete,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import apiClient from "../../utils/requestHandler";

const LocationsPage = () => {
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [stockDetails, setStockDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [newLocation, setNewLocation] = useState({
    location_name: "",
    location_type: "",
  });
  const [loading, setLoading] = useState(true);

  const locationTypes = [
    "Response Vehicle",
    "Main Storage Box",
    "Event Kit Bag",
    "Medical Tent",
    "On-Site Locker",
    "Support Vehicle",
    "Temporary Storage",
  ];

  useEffect(() => {
    const fetchLocations = async () => {
      setLoading(true);
      try {
        const response = await apiClient.get("/api/inventory/locations");
        setLocations(response.data);
      } catch (error) {
        console.error("Error fetching locations:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchLocations();
  }, []);

  const fetchStockDetails = async (locationId) => {
    try {
      const response = await apiClient.get(
        `/api/inventory/locations/${locationId}/stock`
      );
      setStockDetails(response.data);
    } catch (error) {
      console.error("Error fetching stock details:", error);
    }
  };

  const handleSearch = (e) => setSearchTerm(e.target.value);

  const handleDialogOpen = (location = null) => {
    if (location) {
      setNewLocation(location);
      setEditMode(true);
    } else {
      setNewLocation({ location_name: "", location_type: "" });
      setEditMode(false);
    }
    setDialogOpen(true);
  };

  const handleSaveLocation = async () => {
    try {
      if (editMode) {
        await apiClient.put(
          `/api/inventory/locations/${newLocation.location_id}`,
          newLocation
        );
        setLocations((prev) =>
          prev.map((loc) =>
            loc.location_id === newLocation.location_id ? newLocation : loc
          )
        );
      } else {
        const response = await apiClient.post(
          "/api/inventory/locations",
          newLocation
        );
        setLocations((prev) => [...prev, response.data]);
      }
      setDialogOpen(false);
    } catch (error) {
      console.error("Error saving location:", error);
    }
  };

  const handleDeleteLocation = async (locationId) => {
    if (!window.confirm("Are you sure you want to delete this location?"))
      return;

    try {
      await apiClient.delete(`/api/inventory/locations/${locationId}`);
      setLocations((prev) =>
        prev.filter((loc) => loc.location_id !== locationId)
      );
      if (selectedLocation?.location_id === locationId) {
        setSelectedLocation(null);
        setStockDetails([]);
      }
    } catch (error) {
      console.error("Error deleting location:", error);
    }
  };

  const filteredLocations = locations.filter((location) =>
    location.location_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ mt: 8, p: 2 }}>
      <AppBar position="fixed" color="primary">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Location Management
          </Typography>
        </Toolbar>
      </AppBar>

      <Grid container spacing={2}>
        {/* Left Panel: Locations List */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Box sx={{ mb: 2 }}>
              <TextField
                label="Search Locations"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearch}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCircleOutline />}
              fullWidth
              onClick={() => handleDialogOpen()}
            >
              Add New Location
            </Button>
            <List sx={{ mt: 2 }}>
              {filteredLocations.map((location) => (
                <React.Fragment key={location.location_id}>
                  <ListItem
                    button
                    onClick={() => {
                      setSelectedLocation(location);
                      fetchStockDetails(location.location_id);
                      setExpanded(!expanded);
                    }}
                  >
                    <ListItemText
                      primary={location.location_name}
                      secondary={`Type: ${location.location_type}`}
                    />
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                    <IconButton
                      edge="end"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDialogOpen(location);
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      edge="end"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteLocation(location.location_id);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </ListItem>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {selectedLocation &&
                      selectedLocation.location_id === location.location_id && (
                        <Box sx={{ p: 2, backgroundColor: "#f9f9f9" }}>
                          <Typography variant="subtitle1">
                            Stock Details
                          </Typography>
                          {stockDetails.length > 0 ? (
                            <List>
                              {stockDetails.map((item) => (
                                <ListItem key={item.item_id}>
                                  <ListItemText
                                    primary={`${item.item_name} (${item.item_type})`}
                                    secondary={`Quantity: ${item.quantity}`}
                                  />
                                </ListItem>
                              ))}
                            </List>
                          ) : (
                            <Typography>No stock available.</Typography>
                          )}
                        </Box>
                      )}
                  </Collapse>
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* Dialog for Adding/Editing Locations */}
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle>
            {editMode ? "Edit Location" : "Add New Location"}
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <TextField
                label="Location Name"
                value={newLocation.location_name}
                onChange={(e) =>
                  setNewLocation({
                    ...newLocation,
                    location_name: e.target.value,
                  })
                }
                fullWidth
              />
              <TextField
                label="Location Type"
                select
                value={newLocation.location_type}
                onChange={(e) =>
                  setNewLocation({
                    ...newLocation,
                    location_type: e.target.value,
                  })
                }
                fullWidth
              >
                {locationTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
            <Button variant="contained" onClick={handleSaveLocation}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Box>
  );
};

export default LocationsPage;
