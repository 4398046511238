import { createTheme } from "@mui/material/styles";

const baseColors = {
  admin: { primary: "#d32f2f", secondary: "#f57c00" },
  staff: { primary: "#1976d2", secondary: "#4caf50" },
  client: { primary: "#00796b", secondary: "#ff9800" },
  patient: { primary: "#673ab7", secondary: "#9c27b0" },
};

// Generate theme dynamically for roles
export const generateTheme = (role = "staff") => {
  const roleColors = baseColors[role] || baseColors.staff;

  return createTheme({
    palette: {
      primary: {
        main: roleColors.primary,
        light: "#63a4ff",
        dark: "#004ba0",
        contrastText: "#ffffff",
      },
      secondary: {
        main: roleColors.secondary,
        light: "#ffb74d",
        dark: "#f57c00",
        contrastText: "#ffffff",
      },
      success: {
        main: "#4caf50",
        contrastText: "#ffffff",
      },
      error: {
        main: "#f44336",
        contrastText: "#ffffff",
      },
      warning: {
        main: "#ff9800",
        contrastText: "#ffffff",
      },
      info: {
        main: "#2196f3",
        contrastText: "#ffffff",
      },
      background: {
        default: "#f4f6f8",
        paper: "#ffffff",
      },
      text: {
        primary: "#333333",
        secondary: "#555555",
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 14,
      h1: { fontWeight: 700, fontSize: "2.5rem", lineHeight: 1.2 },
      h2: { fontWeight: 700, fontSize: "2rem", lineHeight: 1.3 },
      h3: { fontWeight: 600, fontSize: "1.75rem" },
      h4: { fontWeight: 600, fontSize: "1.5rem" },
      h5: { fontWeight: 500, fontSize: "1.25rem" },
      h6: { fontWeight: 500, fontSize: "1rem" },
      body1: { fontSize: "1rem", lineHeight: 1.5 },
      body2: { fontSize: "0.875rem", lineHeight: 1.4 },
      button: { textTransform: "uppercase", fontWeight: 600 },
    },
    shape: {
      borderRadius: 12,
    },
    shadows: [
      "none",
      "0px 4px 8px rgba(0, 0, 0, 0.1)", // Custom smooth shadow
      ...Array(23).fill("0px 1px 3px rgba(0, 0, 0, 0.2)"),
    ],
    spacing: 8,
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.3)",
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            background: `linear-gradient(90deg, ${roleColors.primary} 0%, ${roleColors.secondary} 100%)`,
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: 12,
          },
        },
      },
      MuiContainer: {
        defaultProps: {
          maxWidth: "lg",
        },
        styleOverrides: {
          root: {
            padding: "16px",
            "@media (max-width: 600px)": {
              padding: "8px",
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 16,
            boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "translateY(-5px)",
              boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.2)",
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          h1: { color: roleColors.primary },
          h2: { color: roleColors.secondary },
          subtitle1: { color: "#616161" },
          subtitle2: { color: "#757575" },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#cccccc",
              },
              "&:hover fieldset": {
                borderColor: roleColors.primary,
              },
              "&.Mui-focused fieldset": {
                borderColor: roleColors.secondary,
                borderWidth: 2,
              },
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: roleColors.primary,
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
              color: roleColors.secondary,
            },
          },
        },
      },
    },
  });
};

export default generateTheme;
